.contenedor_inical{
    position: relative;
}
.contenedor_banner_menu{
    padding-top: 120px;
}
.img_banner_inicial{
    width: 100%;
    position: relative;
}
.curva {
    position: relative;
    background: linear-gradient(to bottom, #4A90E2, #ffffff); /* Gradiente del banner */
    clip-path: ellipse(95% 50% at 50% 44%);
    overflow: hidden;
}
.contenedor_banner_menu_2{
    background-image: url('../images/primera_2025/banner_principal.jpg');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 700px;
    padding: 24% 0%;
    clip-path: ellipse(95% 50% at 50% 44%);
  }
.back_ayuda{
    background-image: url('../images/primera_2025/pleca_patrocinadores.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 4% 0%;
}
.boton_registro_inicial{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 10%;
}

.boton_registro_calendar{
    width: 150px;
    position: absolute;
    z-index: 99;
    bottom: 20%;
    right: 30%;
}

.dudas_btn{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: left;
}
.costo_patrocinio_hoome{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: left;
}
.btn_patrocinio_hoome{
    font-size: 26px;
    background-color: #73aaff;
    line-height: 3px;
    color: #ffffff;
    border-radius: 20px;
    padding: 7px 15px;
    font-family: 'bebasregular';
    border: 2px solid #73aaff;
}

.linea-con-circulos, .linea-con-circulos-2 {
    position: relative;
    width: 100%; /* Ancho de la línea */
    height: 1.5px; /* Grosor de la línea */
    background-color: #ffffff; /* Color de la línea */
    margin: 0px auto; /* Margen para centrar la línea */
  }
  
  .linea-con-circulos::before,
  .linea-con-circulos-2::after {
    content: ''; /* Se requiere para que los pseudoelementos se muestren */
    position: absolute;
    width: 10px; /* Diámetro del círculo */
    height: 10px; /* Diámetro del círculo */
    border-radius: 50%; /* Hace que el elemento sea un círculo */
    background-color: #ffffff; /* Color del círculo */
  }
  
  .linea-con-circulos::before {
    left: 0;
    top: -4px; /* Posiciona el círculo arriba de la línea */
  }
  
  .linea-con-circulos-2::after {
    right: 0;
    top: -4px; /* Posiciona el círculo arriba de la línea */
  }
  
@media screen and (max-width: 767px) {
    .back_ayuda{
        padding: 10% 0%;
    }
    .boton_registro_inicial{
        width: 100px;
        position: absolute;
        z-index: 99;
        }

        .boton_registro_calendar{
            width: 100px;
            position: absolute;
            z-index: 99;
            }
            .contenedor_banner_menu{
                padding-top: 50px;
            }
            .costo_patrocinio_hoome{
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 0px;
                text-align: center;
            }
            .btn_patrocinio_hoome{
                font-size: 18px;
                border-radius: 20px;
                padding: 7px 15px;
            }

            .linea-con-circulos::before,
  .linea-con-circulos-2::after {
    content: ''; /* Se requiere para que los pseudoelementos se muestren */
    position: absolute;
    width: 5px; /* Diámetro del círculo */
    height: 5px; /* Diámetro del círculo */
    border-radius: 50%; /* Hace que el elemento sea un círculo */
    background-color: #ffffff; /* Color del círculo */
  }
  
  .linea-con-circulos::before {
    left: 0;
    top: -2px; /* Posiciona el círculo arriba de la línea */
  }
  
  .linea-con-circulos-2::after {
    right: 0;
    top: -2px; /* Posiciona el círculo arriba de la línea */
  }
}


@media screen and (max-width: 565px) {
    .boton_registro_inicial{
        width: 60px;
        position: absolute;
        z-index: 99;
        bottom: 10%;
        }

        .boton_registro_calendar{
            width: 60px;
            position: absolute;
            z-index: 99;
            bottom: 10%;
            }
            .dudas_btn{
                font-size: 14px;
                text-align: center;
            }
}