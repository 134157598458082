.ant-modal-content{
  background-color: rgba(26, 26, 26, 0.9)!important;
}
/**1° 2025

  
  shape-outside: ellipse(50% 50%);
  clip-path: ellipse(50% 50%);
  height: 350px;

    border-radius: 50% / 60% 60% 60% 60%;
  **/
.contenedor_ponente{
  text-align: center;
  background-color: #0c007e;
  width: 280px;
  border-radius: 130px;
  min-height: 200px;
  padding-top: 10px;
  margin-left: 5%;
}
.img_circulo{
  width: 250px;
  border-radius: 50%;
  border: 5px solid #8002dd;
  text-align: center;
}


/** **/
.contenedor_imagen_con {
  position: relative;
}

.imagen_con {
  display: block;
  width: 100%;
  height: auto;
}

.overlay_con {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(22, 49, 112,0.6);
  border-radius: 0%;
}

.contenedor_imagen_con:hover .overlay_con {
  opacity: 1;
}

.text_con {
  color: white;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 30%;
  -webkit-transform: translate(-20%, -20%);
  -ms-transform: translate(-20%, -20%);
  transform: translate(-20%, -20%);
  text-align: center;
}

.nombre_con{
  font-size: 15px!important;
  font-weight: bold;
  text-transform:uppercase;
  text-align: center;
}

.empresa_con{
  font-size: 12px;
  margin-bottom: 0px;
  text-align: center;
}

.cargo_con{
  font-size: 13px;
  margin-bottom: 0px;
  text-align: center;
}

.nombre_modal{
  font-size: 18px!important;
  color: #ffffff;
  padding-bottom: 5px;
  font-weight: bold;
  text-align: center;
}

.empresa_modal{
  font-size: 16px;
  color: #0ac2c2;
  text-align: center;
}

.cargo_modal{
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: center;
}
.semblanza_modal{
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 0px;
  text-align: justify;
}
.btn_desc_con{
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  
}

.contenedor_conferencista{
  padding: 3% 0% 0% 0%;
}
.contenedor_img_conferencista{
  padding: 0px 0px 5px 0px;
}
.img_conferencista{
  width: 80%;
}
.nombre_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 34px;
  line-height: 30px;
  font-weight: bold;
  color: #414042;
  margin-bottom: 0px;
}
.puesto_conferencista{
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 28px;
  color:#4067b0;
  margin-bottom: 0px;
  line-height: 1.2;

}
.p_text{
  text-align: left;
  margin:0px;
  padding:0px;
}
.conferencia_text{
  font-family: 'Montserrat', sans-serif;
  background-color:#bababb;
  color:#ffffff;
  font-size: 22px;
  padding: 2px 5px;
}
.parrafo_confeencista{
  font-family: 'Montserrat', sans-serif;
  text-align: justify;
  color:#606060;
  font-size: 16px;
}

@media screen and (max-width:700px){
  .puesto_conferencista{
    font-size: 16px;
  }
  .conferencia_text{
    font-size: 14px;
  }
  .nombre_conferencista{
    font-size: 22px;
  }
  .parrafo_confeencista{
    font-size: 14px;
  }

  .contenedor_ponente{
    text-align: center;
    background-color: #0c007e;
    width: 220px;
    border-radius: 130px;
    min-height: 100px;
    padding-top: 10px;
  }
  .img_circulo{
    width: 200px;
    border-radius: 50%;
    border: 5px solid #8002dd;
    text-align: center;
  }
  
}