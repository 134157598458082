.patro_plata{
    padding: 5px 40px;
    font-size: 42px;
    width: 100px;
    color: #8a8a8a;
    font-weight: 900;
}

.patro_oro{
    padding: 5px 60px;
    font-size: 42px;
    width: 100px;
    color: #a68911;
    font-weight: 900;
}
@media screen and (max-width:1200px){
    .patro_plata{
        padding: 5px 20px;
        font-size: 38px;
    }
    .patro_oro{
        padding: 5px 30px;
        font-size: 32px;
    }
}
@media screen and (max-width:700px){
    .patro_plata{
        padding: 5px 20px;
        font-size: 32px;
    }
    .patro_oro{
        padding: 5px 30px;
        font-size: 32px;
    }
}