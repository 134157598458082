.slick-prev:before,
.slick-next:before
{
    color:#242752!important;
}
.slick-prev:before{
    content:'‹';
    font-size: 52px;
    font-weight: bold;
}
.slick-next:before{
    content:'›';
    font-size: 52px;
    font-weight: bold;
}
.back_membresia_home{
    background-image: url('../images/segunda_2024/PNG/lineas_moradas_membresia.png');
    background-color: #012848;
    background-repeat: no-repeat;
    padding: 0% 0% 0% 0%;
    background-size: 100%;
    background-position:0% 0%;

}
.back_azum_membresia{
    background-color: transparent;
    border: 4px solid #75a9ff;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #263259;
    line-height: 1;
    cursor: pointer;
}
.back_azum_membresia_select{
    background-color: #75a9ff;
    border: 4px solid #75a9ff;
    color: #ffffff;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
}

.back_gris_membresia{
    background-color: #8002dd;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 1;
    cursor: pointer;
}
.back_gris_membresia_select{
    background-color: transparent;
    border: 4px solid #8002dd;
    color: #263259;
    display: table!important;
    height:50px;
    border-radius: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
}
.contenedor_membresias_interior {
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 3% 0%;
}
.home_titulo_membresia{
    text-align: left;
    font-size: 16px;
    margin-bottom: 0px;
    color:#ffffff;
    line-height: 1;
    padding-left: 5px;
}
.dvi{

    display: table-cell!important;
   vertical-align: middle!important;
}
.textos_titulos_seccion{
    font-size: 52px;
    line-height: 30px;
    font-weight: bold;
    color: #ffffff;
    padding-top: 3%;
    text-align: left;
    font-family: 'Montserrat';
}
.contenedor_targeta_membresia{
    background-color: rgba(255, 255, 255, 0.8);
    margin: 1% 0%;
    padding: 1%;
}
.nombre_membresia{
    color: #000000;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.link_membresia{
    color: #10447d;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}
.regresar_membresia{
    color: #101010;
    text-align: center;
    font-size: 22px;
}
.titulo_tipo_membresia{
    color: #10447d;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin: 0px;
}
.titulo_membresia{
    color: #1d2143;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
}
.padding_btns{
    padding-top: 0%;
}

.conedor_body {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;

}

.contenedor-botones {
    display: flex;
    align-items: center;
    position: relative;
    height: 90px; /* Altura total del diseño */
    background-color: transparent;
}

.boton-lateral {
    flex: 1; /* Asegura que las secciones laterales ocupen el mismo tamaño */
    height: 100%;
    background: linear-gradient(to right, #010ff4, #8002dd); /* Gradiente */
    border-radius: 40px; /* Bordes redondeados */
}

.boton-lateral-izquierdo {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 15px;
}

.boton-lateral-derecho {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: linear-gradient(to left, #010ff4, #75a9ff); /* Gradiente opuesto */
    margin-left: 15px;
}

.boton-central {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 60%;
    padding: 0 20px;
    background-color: #ffffff; /* Fondo blanco del botón */
    color: #010ff4; /* Color del texto */
    border: none;
    border-radius: 30px;
    font-size: 2rem;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra del botón */
    cursor: pointer;
    z-index: 10;
  text-align: center;
  letter-spacing: 2px;
  font-family: 'bebasregular';
}

.boton-central:hover {
    background-color: #f0f0f0; /* Efecto hover */
}


@media screen and (max-width:767px){
    .boton-central {
        font-size: 1.4rem;
    }
    .contenedor-botones {
        height: 50px; /* Altura total del diseño */
        background-color: transparent;
    }
    .textos_titulos_seccion{
        font-size: 22px;
        line-height: 10px;
    }
    .btn_azul_membresia{
        font-size: 14px;
    }
    .titulo_tipo_membresia{
        font-size: 16px;
    }
    .regresar_membresia{
        font-size: 12px;
    }
    .titulo_membresia{
        font-size: 22px;
    }
    .margin_chico{
        margin: 10px 0px;
    }
    .back_membresia_home{
        background-image: url('');
        background-size: 80px;
        background-position:90% 0%;
    
    }
    .back_azum_membresia, .back_gris_membresia_select, .back_azum_membresia_select, .back_gris_membresia{
        display: table!important;
        height:30px;
        border-radius: 25px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
        line-height: 1;
        cursor: pointer;
    }
    .margin_chico{
        margin: 5px 0px;
    }
}