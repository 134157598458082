
@font-face{
  font-family: 'HandelGothic_TL_Kirillica';
  src: url ('../fonts/reunion/HandelGothic_TL_Kirillica.ttf');
}
@font-face{
  font-family: 'asistant';
  src: url('../TIPOGRAFIAS/ASSISTANT/Assistant-ExtraBold.ttf');
}
@font-face{
  font-family: 'MyFont';
  src: local('MyFont'), url(../fonts/Century_Gothic_Regular.ttf) format('ttf');
}
@font-face{
  font-family: 'Amie';
  src: url('../fonts/amiamie/Amiamie-Regular.ttf');
}

@font-face{
  font-family: 'Amie-bold';
  src: url('../fonts/amiamie/Amiamie-Black-BF63d9b9a5e0d20.otf');
}
@font-face{
  font-family: 'bebasthini';
  src: url('../TIPOGRAFIAS/BEBASNEUE/BebasNeueProBook.otf');
}
@font-face{
  font-family: 'bebasregular';
  src: url('../TIPOGRAFIAS/BEBASNEUE/BebasNeueProRegular.otf');
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');


body{
  font-family: 'Montserrat';
}
.bold_font{
  font-family: 'Montserrat';
}
.font_asistant{
  font-family: 'asistant';
}
.font_bebas{
  font-family: 'bebasregular';
}
a:hover{
  color: unset!important;
}
.tamano_12{
  font-size: 15px;
}
.tamano_14{
  font-size: 18px;
}
.tamano_16{
  font-size: 20px;
}
.tamano_18{
  font-size: 24px;
}
.tamano_20{
  font-size: 26px;
}
.tamano_24{
  font-size: 1.5em;
}
.tamano_26{
  font-size: 34px;
}
.tamano_28{
  font-size: 36px;
}
.tamano_32{
  font-size: 41px;
}
.tamano_40{
  font-size: 52px;
}
.tamano_48{
  font-size: 62px;
}
.color_blanco{
  color:#ffffff;
}
.color_texto_general{
  color: #263259!important;
}
.color_azul{
  color: #010ff4!important;
}
.color_azul_claro{
  color:#75a9ff;
}
.color_azul_oscuro{
  color:#0c007e;
}
.color_aqua{
  color:#72FDEA;
}
.color_gris_oscuro{
  color:#e4e4e4 !important;
}
.color_gris_claro{
  color:#CCCCCC;
}
.hover_color_aqua:hover{
  color:#72FDEA;
}
.gris_reunion{
  color:#bababb;
}
.gris_reunion_oscuro{
  color:#414042;
}
.color_naranja{
  color:#E16848
}
.color_dorado{
  color:#A3850B
}
.color_azul_claro_2{
  color:#62ABBB;
}
.color_hueso{
  color:#EEEAE1;
}
.color_verde{
  color:#55D877;
}
.color_morado{
  color:#8002dd;
}
.back_morado{
  background-color: #8002dd;
}
.color_morado_2{
  color:#373197;
}
.back_morado_2{
  background-color: #373197;
}
.color_morado_3{
  color:#ccc7f6;
}
.back_degradado{
  /*background-image: linear-gradient(to right, #72FDEA, #012535);*/
  background-color: #62AABA;
}
.back_verde{
  background-color:#55D877;
}
.back_blanco{
  background-color:#ffffff;
}
.back_azul{
  background-color: #010ff4;
}
.back_azul_claro{
  background-color: #75a9ff;
}
.back_azul_oscuro{
  background-color: #0c007e;
}
.back_aqua{
  background-color:#72FDEA;
}
.back_naranja{
  background-color:#E16848
}
.back_gris_oscuro{
  background-color:#C9C9C9;
}
.back_gris_claro{
  background-color:#CCCCCC;
}
.back_gris_reunion_oscuro{
  background-color:#414042;
}
.back_tranparencia{
  background-color:rgba(0,0,0,0.4);
}
.back_dorado{
  background-color:#A3850B
}
.back_azul_claro_2{
  background-color:#62ABBB;
}
.back_hueso{
  background-color:#EEEAE1;
}
.font_abel{
  font-family: 'Abel', sans-serif!important;
}
.font_handel{
  font-family: 'MyFont';
}
.titulo_footer_patrocinadores{
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  padding-top: 3%;
  text-align: left;
}
.boton_registrar_header{
  background-color:#5f5fff;
  color: #FFFFFF;
  border-radius: 20px;
}
.boton_registrar_header:hover{
  background-color:#5f5fff;
  color: #FFFFFF;
  color:#ffffff;
}
.textos_titulos_secciones{
  font-size: 38px;
  font-weight: bold;
  padding-top: 1%;
  text-align: center;
  letter-spacing: 2px;
  font-family: 'bebasregular';
}
.textos_titulos_secciones_2{
  font-size: 26px;
  line-height: 30px;
  color: #ffffff;
  padding-top: 3%;
  text-align: left;
  font-family: 'Montserrat';
}
.hr_titulos{
  border:none;
  background-color: #00b7ba;
  height: 5px;
  width: 70%;
  text-align: left;
}
.hr_titulos_blanco{
  border:none;
  background-color: #ffffff;
  height: 5px;
  width: 70%;
  text-align: left;
}
.textos_titulos_secciones_no_olvides{
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  color: #083280;
}
.linea_azul{
  height: 10px;
}

.dia_evento{
  background-color: #00b7ba;
  padding: 4px 10px;
  color:white;
}
.btn_azul{
  background-color: #022435;
  text-align: center;
  font-weight: bold;
  font-size: 22px;
  padding: 15px 25px;
  color: #ffffff;
}
.btn_gris, .btn_gris:hover{
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  padding: 5px 35px;
  color: #0c007e;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'bebasregular';
  letter-spacing: 2px;
}
.btn_blanco, .btn_blanco:hover{
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  font-size: 26px;
  padding: 5px 35px;
  color: #0c007e;
  border-radius: 50px;
  cursor: pointer;
  font-family: 'bebasregular';
  letter-spacing: 2px;
  box-shadow: 2px 2px 2px 2px #f2f6ff;
}
.btn_aqua, .btn_aqua:hover{
  background-color: #8002dd;
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  padding: 15px 25px;
  color: #ffffff;
  border-radius: 50px;
  cursor: pointer;
  line-height: 3px;
}
.btn_aqua2, .btn_aqua2:hover{
  background-color: #5348dd;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  padding: 20px 30px;
  color: #ffffff;
  border-radius: 50px;
  border-color: #8002dd;
}
.btn_aqua_agenda, .btn_aqua_agenda:hover{
  background-color: #73aaff;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  padding: 5px 15px;
  color: #ffffff;
  border-color: 2px solid #73aaff;
}
.img_100{
  width: 100%;
}
.paddin_contenedor{
  padding: 1% 0%;
}
.ayuda_back{
  background-image: url('')!important;
  background-repeat: no-repeat;
  background-size: 100%;
}
.puntero{
  cursor: pointer;
}
.no_ver_grande{
  display: none;
}
.no_ver_chico{
  display: block;
}
@media screen and (max-width:700px){
  .btn_gris, .btn_gris:hover{
    font-size: 12px;
    padding: 5px 35px;
    border-radius: 50px;
  }
  .no_ver_grande{
    display: block;
  }
  .no_ver_chico{
    display: none;
  }
  .textos_titulos_secciones{
    font-size: 24px;
    line-height: 20px;
    font-weight: bold;
    margin: 10px 0px 10px 0px!important;
  }
  .textos_titulos_secciones_2{
    font-size: 16px;
    line-height: 20px;
  }
  .titulo_footer_patrocinadores{
    font-size: 20px;
    line-height: 20px;
  }
  .textos_titulos_secciones_no_olvides{
    font-size: 16px;
    line-height: 18px;
  }
  .btn_aqua, .btn_azul, .btn_azul:hover, .btn_aqua:hover, .btn_aqua2{
    font-size: 24px;
    padding: 5px 10px;
  }
  .tamano_14{
    font-size: 12px;
  }
  .tamano_16{
    font-size: 14px;
  }
  .tamano_12{
    font-size: 10px;
  }
  .ayuda_back{
    background-image: url('');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
