.estilo_campos{
    color:white;
}
.ant-form label.visitas_texto{
    font-size: 15px!important;
}
.ant-input{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;

}
.ant-input-affix-wrapper{
    background-color: #f1f2f2!important;
    border: 0px solid #d9d9d9!important;
}
.texto_registro{
    color: #263259;
    font-size: 18px;
    font-weight: bold;
}
.nom-label, .nom-label::placeholder{
    color:#263259!important;
    font-size: 18px!important;
}
.nom-label-cel{
    color:#538ae4!important;
    font-size: 18px!important;
}
.num-men{
    background-color: #538ae4;
    color:#ffffff;
    font-size: 18px;
}
.ant-form-item-required{
}
.cuadro_opciones{
    text-align: left;
    padding: 0px 20px 10px 20px;
    border-radius: 24px;
    font-size: 14px!important;
}
.cuadro_opciones_titulo{
    text-align: left;
    border-color: #73aafe;
    border-width: 2px;
    border-style: solid;
    padding: 10px;
    border-radius: 24px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}
.cupos_texto{
    color:#8002dd;
}
.agregar_persona{
    background-color: #62abbb;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}
.quitar_persona{
    background-color: #e16948;
    color:#ffffff;
    text-align: center;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 18px;
    cursor: pointer;
}
@media only screen and (max-width: 700px) {
    .nom-label, .nom-label-cel, .num-men, .ant-form label{
        font-size: 12px!important;
    }
    .ant-form-item-required{
        font-size: 12px!important;
    }
    .cuadro_opciones{
        padding: 0px 0px 10px 0px;
        font-size: 12px!important;
    }
    .cuadro_opciones_titulo{
        border-width: 0px;
        padding: 5px;
        font-size: 12px;
    }
}
.transmision{
    width: 50%;
}

.select_telefono{
    width:100px;
}

.boton_recibir_codigo{
    color: #ffffff !important;
    background-color:#4067b0 !important;
    border: 3px solid #4067b0!important;
    font-weight: bold;
    cursor: pointer;
}

.boton_registrate{
    color: #538ae4 !important;
}

.boton_sin_login{
    background-color: #083280 !important;
    color: white !important;
}
.selector_radio_opcion{
    color:black;
}

.selector_radio_opcion:hover{
    color:#538ae4 !important;
}

.selector_radio_opcion:focus{
    color:#538ae4 !important;
}

