.texto_costos{
    font-size: 16px;
    text-align: left;
}
.texto_costos_grande{
    font-size: 22px;
    text-align: left;
    font-weight: bold;
}
.texto_costos_grande_2{
    font-size: 26px;
    text-align: left;
    font-weight: bold;
    line-height: 1.2;
}
.contenedor_costo{
    background-color: rgba(0,0,0,0.2);
    border-radius: 20px;
    text-align: left;
    padding: 25px 25px;
}
.datos_sub{
    font-size: 28px;
    text-align: center;
}
.transferen_text{
    font-size: 22px;
}
.block_titulo{
    font-size: 19px;
    font-weight: bold;
}
.block_info{
    font-size: 15px;
    font-weight: bold;
}
.texto_final_costos{
    font-size: 16px;
    text-align: center;
}
.back_costos_sec{
    background-image: url('../images/primera_2025/COSTOS/back_cuentas.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position:0% 40%;
  background-attachment: fixed;
  
}
.costos_curva {
    position: relative;
    background: linear-gradient(to bottom, #ffffff, #ffffff); /* Gradiente del banner */
    clip-path: ellipse(95% 50% at 50% 44%);
    overflow: hidden;
}
@media screen and (max-width:767px){
    .back_costos_sec{
        background-image: url('../images/segunda_2024/PNG/lineasmoradas_costos.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position:0% 10%;
    }
    .texto_costos_grande{
        font-size: 16px;
        text-align: left;
    }
    .texto_costos_grande_2{
        font-size: 15px;
        text-align: left;
    }
    .texto_costos{
        font-size: 13px;
        text-align: left;
    }
    .datos_sub{
        font-size: 22px;
        text-align: center;
    }
    .transferen_text{
        font-size: 14px;
        text-decoration: underline;
    }
    .block_titulo{
        font-size: 15px;
        font-weight: bold;
    }
    .block_info{
        font-size: 13px;
        font-weight: bold;
    }
    .texto_final_costos{
        font-size: 14px;
        text-align: center;
    }
}